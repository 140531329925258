.labelImage {
  position: relative;
  line-height: 22px;
  padding-top: 0px;
  top: 15px;
  margin-top: 1.5em;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top 0px;
  pointer-events: none;
  user-select: none;
  color: #616161;
  font-size: 14pt;
  font-weight: bold;
  letter-spacing: 1px;
}

.handle {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z" color="#000"></path></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
}

.grid {
  display: block;
  width: 130 * 4px;
  height: 350px;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
}

.gridItem {
  float: left;
  width: 130px;
  padding: 8px;
  background: transparent;
  border: 0;
}

.gridItem .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #efefef;
  font-size: 28px;
}

.gridItem .wrapper span {
  display: none;
}
